import './bootstrap.js';

document.addEventListener("DOMContentLoaded", () => {
    const moneyInputs = document.querySelectorAll("[role='money']");
    moneyInputs.forEach(input => {
        input.addEventListener("input", () => {
            input.value = formatCurrencyInput(input.value);
        });
        //seperate thousands with space:
        // input.addEventListener("input", () => {
        //     let value = input.value.replace(/D/g, "");
        //     let formattedValue = value.replace(/(d)(?=(d{3})+(?!d))/g, "$1 ");
        //     input.value = formattedValue;
        // });
        
        input.value = formatCurrencyInput(input.value);
    });
});

window.formatCurrencyInput = (input) => {
    // Definujeme zkratky a jejich hodnoty
    const multipliers = {
        'mil': 1_000_000,
        'tis': 1_000,
        'k': 1_000
    };

    // Vyčištění vstupu: odstraníme mezery a převedeme na malé písmo
    let sanitizedInput = input.toLowerCase().replace(/\s+/g, '');

    // Zkontrolujeme, zda vstup obsahuje některou ze zkratek (např. "1mil", "5.5k")
    let match = sanitizedInput.match(/^([\d,.]+)(mil|k)?$/);
    
    if (!match) return input; // Pokud neodpovídá formátu čísla, vrátíme původní vstup

    let number = parseFloat(match[1].replace(',', '.')); // Převedeme číslo
    if (isNaN(number)) return input; // Pokud není validní číslo, vrátíme vstup

    // Pokud byla nalezena zkratka, vynásobíme odpovídajícím koeficientem
    if (match[2]) {
        number *= multipliers[match[2]];
    }

    // Formátování čísla s oddělením tisíců mezerou
    return number.toLocaleString('cs-CZ').replace(/\u00A0/g, ' ');
}

window.soudy = {
    "KSUL": "Krajský soud v Ústí nad Labem",
    "MSPH": "Městský soud v Praze",
    "KSBR": "Krajský soud v Brně",
    "KSHK": "Krajský soud v Hradci Králové",
    "KSPH": "Krajský soud v Praze",
    "KSCB": "Krajský soud v Českých Budějovicích",
    "KSKO": "Krajský soud v Ostravě",
    "KSPL": "Krajský soud v Plzni",
    "KSPA": "Krajský soud v Pardubicích",
    "KSOL": "Krajský soud v Olomouci",
    "KSZL": "Krajský soud ve Zlíně",
};

window.financniUrady = [
    { kod: "000", nazev: "Generální finanční ředitelství" },
    { kod: "001", nazev: "Územní pracoviště pro Prahu 1" },
    { kod: "002", nazev: "Územní pracoviště pro Prahu 2" },
    { kod: "003", nazev: "Územní pracoviště pro Prahu 3" },
    { kod: "004", nazev: "Územní pracoviště pro Prahu 4" },
    { kod: "005", nazev: "Územní pracoviště pro Prahu 5" },
    { kod: "006", nazev: "Územní pracoviště pro Prahu 6" },
    { kod: "007", nazev: "Územní pracoviště pro Prahu 7" },
    { kod: "008", nazev: "Územní pracoviště pro Prahu 8" },
    { kod: "009", nazev: "Územní pracoviště pro Prahu 9" },
    { kod: "010", nazev: "Územní pracoviště pro Prahu 10" },
    { kod: "011", nazev: "Územní pracoviště pro Prahu - Jižní Město" },
    { kod: "012", nazev: "Územní pracoviště v Praze - Modřanech" },
    { kod: "013", nazev: "Specializovaný finanční úřad" },
    { kod: "021", nazev: "Územní pracoviště v Benešově" },
    { kod: "022", nazev: "Územní pracoviště ve Vlašimi" },
    { kod: "026", nazev: "Územní pracoviště v Berouně" },
    { kod: "027", nazev: "Územní pracoviště v Hořovicích" },
    { kod: "030", nazev: "Územní pracoviště v Kladně" },
    { kod: "031", nazev: "Územní pracoviště ve Slaném" },
    { kod: "034", nazev: "Územní pracoviště v Kolíně" },
    { kod: "038", nazev: "Územní pracoviště v Kutné Hoře" },
    { kod: "039", nazev: "Územní pracoviště v Čáslavi" },
    { kod: "043", nazev: "Územní pracoviště v Mělníce" },
    { kod: "044", nazev: "Územní pracoviště v Kralupech nad Vltavou" },
    { kod: "048", nazev: "Územní pracoviště v Mladé Boleslavi" },
    { kod: "053", nazev: "Územní pracoviště v Nymburku" },
    { kod: "055", nazev: "Územní pracoviště v Poděbradech" },
    { kod: "057", nazev: "Územní pracoviště Praha-východ; Územní pracoviště v Brandýse n.L.-Staré Bol." },
    { kod: "059", nazev: "Územní pracoviště v Říčanech" },
    { kod: "060", nazev: "Územní pracoviště Praha-západ" },
    { kod: "063", nazev: "Územní pracoviště v Příbrami" },
    { kod: "069", nazev: "Územní pracoviště v Rakovníku" },
    { kod: "077", nazev: "Územní pracoviště v Českých Budějovicích" },
    { kod: "082", nazev: "Územní pracoviště v Českém Krumlově" },
    { kod: "086", nazev: "Územní pracoviště v Jindřichově Hradci" },
    { kod: "091", nazev: "Územní pracoviště v Pelhřimově" },
    { kod: "097", nazev: "Územní pracoviště v Písku" },
    { kod: "101", nazev: "Územní pracoviště v Prachaticích" },
    { kod: "105", nazev: "Územní pracoviště ve Strakonicích" },
    { kod: "110", nazev: "Územní pracoviště v Táboře" },
    { kod: "118", nazev: "Územní pracoviště v Domažlicích" },
    { kod: "123", nazev: "Územní pracoviště v Chebu" },
    { kod: "128", nazev: "Územní pracoviště v Karlových Varech" },
    { kod: "133", nazev: "Územní pracoviště v Klatovech" },
    { kod: "138", nazev: "Územní pracoviště v Plzni" },
    { kod: "140", nazev: "Územní pracoviště Plzeň-jih" },
    { kod: "145", nazev: "Územní pracoviště Plzeň-sever" },
    { kod: "150", nazev: "Územní pracoviště v Rokycanech" },
    { kod: "155", nazev: "Územní pracoviště v Sokolově" },
    { kod: "160", nazev: "Územní pracoviště v Tachově" },
    { kod: "172", nazev: "Územní pracoviště v České Lípě" },
    { kod: "178", nazev: "Územní pracoviště v Děčíně" },
    { kod: "179", nazev: "Územní pracoviště v Rumburku" },
    { kod: "182", nazev: "Územní pracoviště v Chomutově" },
    { kod: "183", nazev: "Územní pracoviště v Kadani" },
    { kod: "187", nazev: "Územní pracoviště v Jablonci nad Nisou" },
    { kod: "192", nazev: "Územní pracoviště v Liberci" },
    { kod: "196", nazev: "Územní pracoviště v Litoměřicích" },
    { kod: "198", nazev: "Územní pracoviště v Roudnici nad Labem" },
    { kod: "201", nazev: "Územní pracoviště v Lounech" },
    { kod: "203", nazev: "Územní pracoviště v Žatci" },
    { kod: "206", nazev: "Územní pracoviště v Mostě" },
    { kod: "210", nazev: "Územní pracoviště v Teplicích" },
    { kod: "214", nazev: "Územní pracoviště v Ústí nad Labem" },
    { kod: "223", nazev: "Územní pracoviště v Havlíčkově Brodě" },
    { kod: "228", nazev: "Územní pracoviště v Hradci Králové" },
    { kod: "233", nazev: "Územní pracoviště v Chrudimi" },
    { kod: "238", nazev: "Územní pracoviště v Jičíně" },
    { kod: "243", nazev: "Územní pracoviště v Náchodě" },
    { kod: "248", nazev: "Územní pracoviště v Pardubicích" },
    { kod: "253", nazev: "Územní pracoviště v Rychnově nad Kněžnou" },
    { kod: "258", nazev: "Územní pracoviště v Semilech" },
    { kod: "260", nazev: "Územní pracoviště v Turnově" },
    { kod: "263", nazev: "Územní pracoviště ve Svitavách" },
    { kod: "268", nazev: "Územní pracoviště v Trutnově" },
    { kod: "273", nazev: "Územní pracoviště v Ústí nad Orlicí" },
    { kod: "274", nazev: "Územní pracoviště ve Vysokém Mýtě" },
    { kod: "275", nazev: "Územní pracoviště v Žamberku" },
    { kod: "283", nazev: "Územní pracoviště v Blansku" },
    { kod: "284", nazev: "Územní pracoviště v Boskovicích" },
    { kod: "288", nazev: "Územní pracoviště Brno I" },
    { kod: "289", nazev: "Územní pracoviště Brno II" },
    { kod: "290", nazev: "Územní pracoviště Brno III" },
    { kod: "291", nazev: "Územní pracoviště Brno IV" },
    { kod: "293", nazev: "Územní pracoviště Brno-venkov" },
    { kod: "298", nazev: "Územní pracoviště v Břeclavi" },
    { kod: "299", nazev: "Územní pracoviště v Hustopečích" },
    { kod: "303", nazev: "Územní pracoviště ve Zlíně" },
    { kod: "305", nazev: "Územní pracoviště v Otrokovicích" },
    { kod: "309", nazev: "Územní pracoviště v Hodoníně" },
    { kod: "310", nazev: "Územní pracoviště v Kyjově" },
    { kod: "311", nazev: "Územní pracoviště ve Veselí nad Moravou" },
    { kod: "314", nazev: "Územní pracoviště v Jihlavě" },
    { kod: "320", nazev: "Územní pracoviště v Kroměříži" },
    { kod: "325", nazev: "Územní pracoviště v Prostějově" },
    { kod: "330", nazev: "Územní pracoviště v Třebíči" },
    { kod: "336", nazev: "Územní pracoviště v Uherském Hradišti" },
    { kod: "338", nazev: "Územní pracoviště v Uherském Brodě" },
    { kod: "341", nazev: "Územní pracoviště ve Vyškově" },
    { kod: "346", nazev: "Územní pracoviště ve Znojmě" },
    { kod: "351", nazev: "Územní pracoviště ve Žďáru nad Sázavou" },
    { kod: "353", nazev: "Územní pracoviště ve Velkém Meziříčí" },
    { kod: "358", nazev: "Územní pracoviště v Bruntále" },
    { kod: "359", nazev: "Územní pracoviště v Krnově" },
    { kod: "362", nazev: "Územní pracoviště ve Frýdku-Místku" },
    { kod: "364", nazev: "Územní pracoviště v Třinci" },
    { kod: "367", nazev: "Územní pracoviště v Karviné" },
    { kod: "370", nazev: "Územní pracoviště v Havířově" },
    { kod: "374", nazev: "Územní pracoviště v Novém Jičíně" },
    { kod: "376", nazev: "Územní pracoviště v Kopřivnici" },
    { kod: "379", nazev: "Územní pracoviště v Olomouci" },
    { kod: "381", nazev: "Územní pracoviště ve Šternberku" },
    { kod: "384", nazev: "Územní pracoviště v Opavě" },
    { kod: "388", nazev: "Územní pracoviště Ostrava I" },
    { kod: "389", nazev: "Územní pracoviště Ostrava II" },
    { kod: "390", nazev: "Územní pracoviště Ostrava III" },
    { kod: "393", nazev: "Územní pracoviště v Přerově" },
    { kod: "394", nazev: "Územní pracoviště v Hranicích" },
    { kod: "398", nazev: "Územní pracoviště v Šumperku" },
    { kod: "399", nazev: "Územní pracoviště v Jeseníku" },
    { kod: "400", nazev: "Územní pracoviště v Zábřehu" },
    { kod: "403", nazev: "Územní pracoviště ve Vsetíně" },
    { kod: "404", nazev: "Územní pracoviště v Rožnově pod Radhoštěm" },
    { kod: "405", nazev: "Územní pracoviště ve Valašském Meziříčí" },
    { kod: "451", nazev: "Finanční úřad pro hlavní město Prahu" },
    { kod: "452", nazev: "Finanční úřad pro Středočeský kraj" },
    { kod: "453", nazev: "Finanční úřad pro Jihočeský kraj" },
    { kod: "454", nazev: "Finanční úřad pro Plzeňský kraj" },
    { kod: "455", nazev: "Finanční úřad pro Karlovarský kraj" },
    { kod: "456", nazev: "Finanční úřad pro Ústecký kraj" },
    { kod: "457", nazev: "Finanční úřad pro Liberecký kraj" },
    { kod: "458", nazev: "Finanční úřad pro Královéhradecký kraj" },
    { kod: "459", nazev: "Finanční úřad pro Pardubický kraj" },
    { kod: "460", nazev: "Finanční úřad pro Kraj Vysočina" },
    { kod: "461", nazev: "Finanční úřad pro Jihomoravský kraj" },
    { kod: "462", nazev: "Finanční úřad pro Olomoucký kraj" },
    { kod: "463", nazev: "Finanční úřad pro Moravskoslezský kraj" },
    { kod: "464", nazev: "Finanční úřad pro Zlínský kraj" },
];